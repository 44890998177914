body {
  color: #797979;
  background: #ffffff;
  font-family: 'Open Sans', sans-serif;
}

h1,
h2, 
h3, 
h4,
h5, 
h6 {
  color: #1d2434;
}

a {
  color: #454545;
  transition: .3s;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: #D5B981;
  outline: none;
  text-decoration: none;
}

.btn:focus,
.form-control:focus {
  box-shadow: none;
}

.container-fluid {
  max-width: 1366px;
}

.back-to-top {
  position: fixed;
  display: none;
  background: #D5B981;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top:hover {
  background: #1d2434;
}

.back-to-top:hover i {
  color: #D5B981;
}

.back-to-top i {
  color: #1d2434;
  padding-top: 10px;
}

.section-header {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 45px;
}

.section-header p {
  margin-bottom: 5px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
}

.section-header h2 {
  margin: 0;
  position: relative;
  font-size: 50px;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .section-header h2 {
      font-size: 30px;
  }
}