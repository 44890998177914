.AdminGalleryPage {

  .AddGalleryButtonContainer {
    padding: 20px;
  }
  
  .TableHeader {
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #d6d6d6;
    box-shadow: 0px 4px 5px #b9b9b9;

    > div {
      flex: 1;
    }

    .ActionsHolder {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
    }
  }

  .GalleryConainer {
    height: calc(100vh - 185px);
    overflow: auto;

    .GalleryItemHolder {
      display: flex;
      align-items: center;
      padding: 20px;
      justify-content: space-between;
      border-bottom: 1px solid #d6d6d6;
  
      > div {
        flex: 1;
      }

      .ImageHolder {
  
        img {
          width: 200px;
        }
      }
  
      .ButtonHolder {
        display: flex;
        justify-content: flex-end;
        
        button {
          margin-right: 10px;
        }
      }
    }
  }

 

  .DeleteModal {
    .ModalDialogBody {
      padding: 20px;

      .ModalButtonContainer {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
      }
    }
  }
}