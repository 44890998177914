.LoginPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .LogoContainer {
    img {
      width: 150px;
      margin-bottom: 30px;
    }
  }

  .FormContainer {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 10px;
      outline: none;
      border: 1px solid #000;
      padding: 5px 10px;
    }
  }
}