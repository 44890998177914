.AdminServicesPage {
  
  .AddPhotosButtonContainer {
    padding: 20px;
  }
  
  .TableHeader {
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #d6d6d6;
    box-shadow: 0px 4px 5px #b9b9b9;

    > div {
      flex: 1;
    }

    .ActionsHolder {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
    }
  }

  .ServicesContainer {
    height: calc(100vh - 185px);
    overflow: auto;

    .ServiceItemHolder {
      display: flex;
      align-items: center;
      padding: 20px;
      // justify-content: space-between;
      border-bottom: 1px solid #d6d6d6;
  
      > div {
        flex: 1;
      }

      .ButtonHolder {
        display: flex;
        justify-content: flex-end;
      }

      .ImageHolder {
    
        img {
          width: 200px;
        }
      }

      .DescriptionHolder {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* number of lines to show */
        -webkit-box-orient: vertical;  
      }
    
      .ButtonHolder {
        button {
          margin-right: 10px;
        }
      }
    }
  }

  .DeleteModal {
    .ModalDialogBody {
      padding: 20px;

      .ModalButtonContainer {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
      }
    }
  }
}
