.AdminPhotosPage {

  .AddPhotosButtonContainer {
    padding: 20px;
  }

  .GalleryTitle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .PhotosContainer {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    .ImageContainer {
      position: relative;

      .ant-image {
        margin: 5px;
      }

      .DeleteImageContainer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 6px;
        right: 5px;
        background: #00000073;
        width: 50px;
        height: 50px;
        color: red;
        font-size: 18px;
        opacity: 0;
        transition: 0.5;
        font-weight: 600;

        i {
          font-weight: 600;
        }
      }

      &:hover {
        .DeleteImageContainer {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}