.SingleServicePage {
  .ServiceContainer {
    img {
      width: 100%;
    }

    .ServiceHeader {
      margin-bottom: 30px;
      margin-left: 0;
      position: relative;
      margin: 0 auto;
      margin-bottom: 45px;
  
      p {
        margin-bottom: 5px;
        position: relative;
        font-size: 16px;
        font-weight: 600;
      }
      h2 {
        margin: 0;
        position: relative;
        font-size: 50px;
        font-weight: 700;
      }
    }
  } 
  .page-header {
      position: relative;
      margin-bottom: 45px;
      padding: 150px 0 90px 0;
      text-align: center;
      background: #D5B981;
  }
  
  .page-header h2 {
      position: relative;
      color: #1d2434;
      font-size: 60px;
      font-weight: 700;
  }
  
  .page-header a {
      position: relative;
      padding: 0 12px;
      font-size: 22px;
      color: #1d2434;
  }
  
  .page-header a:hover {
      color: #ffffff;
  }
  
  .page-header a::after {
      position: absolute;
      content: "/";
      width: 8px;
      height: 8px;
      top: -2px;
      right: -7px;
      text-align: center;
      color: #1d2434;
  }
  
  .page-header a:last-child::after {
      display: none;
  }
  
  @media (max-width: 767.98px) {
      .page-header h2 {
          font-size: 35px;
      }
      
      .page-header a {
          font-size: 18px;
      }
  }
 
  
}