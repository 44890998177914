.GalleryPage {
  .page-header {
      position: relative;
      margin-bottom: 45px;
      padding: 150px 0 90px 0;
      text-align: center;
      background: #D5B981;
  }
  
  .page-header h2 {
      position: relative;
      color: #1d2434;
      font-size: 60px;
      font-weight: 700;
  }
  
  .page-header a {
      position: relative;
      padding: 0 12px;
      font-size: 22px;
      color: #1d2434;
  }
  
  .page-header a:hover {
      color: #ffffff;
  }
  
  .page-header a::after {
      position: absolute;
      content: "/";
      width: 8px;
      height: 8px;
      top: -2px;
      right: -7px;
      text-align: center;
      color: #1d2434;
  }
  
  .page-header a:last-child::after {
      display: none;
  }
  
  @media (max-width: 767.98px) {
      .page-header h2 {
          font-size: 35px;
      }
      
      .page-header a {
          font-size: 18px;
      }
  }



  .GalleryImageContainer {
    margin-bottom: 15px;
  }

  .ImageEffect {
    overflow: hidden;
  }

  .ImageEffect img {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(1.12);
    transform: scale(1.12);
    width: 100%;
  }
  .ImageEffect:hover {
    cursor: pointer;
  }

  .ImageEffect:hover img {
    opacity: 0.5;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  .ImageEffect .ImageCaption {
    padding: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ImageEffect .ImageCaption::before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 1px solid #fff;
    content: '';
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .ImageEffect .ImageCaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
  
  .ImageEffect .ImageCaption::before {
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
  }
  
  .ImageEffect h2 {
    padding: 10px;
    margin: 0;
    text-align: center;
    color: #fff;
    background: #00000059;
    font-size: 20px;
  }

  .ImageEffect .ImageCaption, .ImageEffect .ImageCaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
      
  .ImageEffect:hover .ImageCaption::before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  @media screen and (max-width: 50em) {
    .content {
      padding: 0 10px;
      text-align: center;
    }
    .ImageEffect {
      display: inline-block;
      float: none;
      margin: 10px auto;
      width: 100%;
    }
  }

  @media (max-width: 767.98px) {
    .page-header {
      padding: 50px 0 50px 0;
    }
  } 
}