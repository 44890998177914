.FooterComponent {
  .footer {
      position: relative;
      margin-top: 45px;
      padding-top: 20px;
      background: #1d2434;
  }
  
  .footer .footer-contact,
  .footer .footer-link {
      position: relative;
      margin-bottom: 15px;
      color: #999999;
  }

  .footer .footer-link  {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
  }
  
  .footer .footer-contact h2,
  .footer .footer-link h2{
      position: relative;
      margin-bottom: 30px;
      font-size: 22px;
      font-weight: 700;
      color: #D5B981;
  }
  
  .footer .footer-link a {
      display: block;
      margin-bottom: 10px;
      color: #999999;
      transition: .3s;
      text-decoration: none;
  }
  
  .footer .footer-link a::after {
      position: relative;
      content: "\f104";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-left: 10px;
  }
  
  .footer .footer-link a:hover {
      color: #D5B981;
      letter-spacing: 1px;
  }
  
  .footer .footer-contact p i {
      width: 25px;
  }
  
  .footer .footer-social {
      position: relative;
      margin-top: 20px;
  }
  
  .footer .footer-social a {
      display: inline-block;
  }
  
  .footer .footer-social a i {
      margin-right: 15px;
      font-size: 18px;
      color: #D5B981;
  }
  
  .footer .footer-social a:last-child i {
      margin: 0;
  }
  
  .footer .footer-social a:hover i {
      color: #999999;
  }
  
  .logo {
    width: 100%;
    padding-bottom: 20px;
  }

  @media (max-width: 767.98px) {
    .logo{
      display: block;
      width: 40%;
      margin: auto;
    }

    .footer {
      .footer-link{
        align-items: center;
      }

      .footer-contact {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
}