@import '../../variables';

.ModalDialog {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
  color: #a672a9;

  .Background.disabled {
    pointer-events: none;
  }

  .Background {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .ModalDialogHeader {
    padding: 0 20px;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: $white;
    min-height: 48px;
    margin-bottom: -1px;
    font-weight: 500;
    font-size: 16px;
    // border-bottom: 1px solid red;
    zoom: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #1d2434;


    .ModalDialogTitle {
      display: flex;
      flex: 1;
      min-width: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .ModalContainer.disabled {
    pointer-events: none;
  }

  .ModalContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    max-width: 60%;
    background: #fff;
    margin: auto;
    border-radius: 20px;

    .ant-card-body {
      padding: 0;
    }
  }
}