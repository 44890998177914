.ContactPage {
  .page-header {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background: #D5B981;

    h2 {
      position: relative;
      color: #1d2434;
      font-size: 60px;
      font-weight: 700;
    }

    a {
      position: relative;
      padding: 0 12px;
      font-size: 22px;
      color: #1d2434;

      &:hover {
        color: #ffffff;
      }

      &::after{
        position: absolute;
        content: "/";
        width: 8px;
        height: 8px;
        top: -2px;
        right: -7px;
        text-align: center;
        color: #1d2434;
      }

      &:last-child{

        &::after {
          display: none;
        }
      }
    }
  }
  .ContactHolder {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .InfoHolder {
      display: flex;
      flex-direction: column;

      .infoContainer{
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
          margin-left: 20px;
          font-size: 16px;
        }
      }

      i {
        font-size: 36px;
        color: #454545;
        width: 30px;
      }

      .SocialMediaHolder {

        span {
          margin-right: 20px;
        }
      }
    }

    .MapHolder {
      display: flex;
    }
  }
  

  @media (max-width: 767.98px) {
    .page-header {
      padding: 50px 0 50px 0;
      h2 {
        font-size: 35px;
      }
      a {
        font-size: 18px;
      }
    }

    .ContactHolder {
      flex-direction: column;

      .InfoHolder{
        margin-bottom: 20px;
      }

      .MapHolder {
        width: 100%;
      }
    }
  }
}

