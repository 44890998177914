.HeaderComponent {
    .top-bar {
        position: relative;
        height: 60px;
        background: #1d2434;
    }

    .top-bar .top-bar-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .top-bar .top-bar-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .top-bar .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 60px;
        padding: 0 10px;
        text-align: center;
        border-left: 1px solid rgba(255, 255, 255, .15);
    }

    .top-bar .text:last-child {
        border-right: 1px solid rgba(255, 255, 255, .15);
    }

    .top-bar .text h2 {
        color: #eeeeee;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1px;
        margin: 0;
    }

    .top-bar .text p {
        color: #eeeeee;
        font-size: 12px;
        font-weight: 400;
        margin: 0;
    }

    .top-bar .social {
        display: flex;
        height: 60px;
        font-size: 0;
        justify-content: flex-end;
    }

    .top-bar .social a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 100%;
        font-size: 22px;
        color: #ffffff;
        text-decoration: none;
        border-right: 1px solid rgba(255, 255, 255, .15);
    }

    .top-bar .social a:first-child {
        border-left: 1px solid rgba(255, 255, 255, .15);
    }

    .top-bar .social a:hover {
        color: #D5B981;
        background: #ffffff;
    }

    @media (min-width: 992px) {
        .top-bar {
            padding: 0 60px;
        }
    }


    .navbar {
        position: relative;
        transition: .5s;
        z-index: 999;
    }

    .logo {
      width: 200px;
      position: absolute;
      transition: .5s;
    }

    .navbar.nav-sticky {
        position: fixed;
        top: 0;
        width: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .3);

        .logo {
          width: 150px;
        }
    }

    .navbar .navbar-brand {
        margin: 0;
        color: #ffffff;
        font-size: 45px;
        line-height: 0px;
        font-weight: 600;
    }

    .navbar .navbar-brand span {
        font-weight: 800;
    }

    .navbar .navbar-brand img {
        max-width: 100%;
        max-height: 40px;
    }

    .navbar-expand-lg .navbar-collapse {
        flex-direction: row-reverse;
    }

    .navbar-dark .navbar-nav .nav-link,
    .navbar-dark .navbar-nav .nav-link:focus,
    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link.active {
        padding: 10px 10px 8px 10px;
        color: #ffffff;
    }

    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link.active {
        background: rgba(256, 256, 256, .1);
        transition: none;
    }

    .navbar .dropdown-menu {
        margin-top: 0;
        border: 0;
        border-radius: 0;
        background: #f8f9fa;
    }

    @media (min-width: 992px) {
        .navbar {
            position: absolute;
            width: 100%;
            top: 60px;
            padding: 20px 60px;
            background: rgba(0, 0, 0, .35) !important;
            z-index: 9;
        }
        
        .navbar.nav-sticky {
            padding: 10px 60px;
            background: #1d2434 !important;
        }
        
        .navbar a.nav-link {
            padding: 8px 15px;
            font-size: 14px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    @media (max-width: 991.98px) {   
        .navbar {
            padding: 15px;
            background: #1d2434 !important;
        }
        
        .navbar a.nav-link {
            padding: 5px;
        }
        
        .navbar .dropdown-menu {
            box-shadow: none;
        }
    }
}

@media screen and (max-width: 991px) {
    .HeaderComponent {
      
      .logo {
        position: relative;
        width: 150px
      }
    }
  }