.AboutUsComponent {

    .about {
        position: relative;
        width: 100%;
        padding: 45px 0;

        .section-header {
          margin-bottom: 30px;
          margin-left: 0;
      }

      .about-img {
        position: relative;
        height: 100%;
        margin: 60px 0 0 60px;

        img {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
       }
      }
    }
    
    .about .about-img::before {
        position: absolute;
        content: "";
        top: -60px;
        right: 60px;
        bottom: 60px;
        left: -60px;
        background: #1d2434;
        z-index: 0;
    }
    
    .about .about-img::after {
        position: absolute;
        content: "";
        top: -30px;
        right: 30px;
        bottom: 30px;
        left: -30px;
        background: transparent;
        border: 1px solid #ffffff;
        z-index: 2;
    }
    .about-text {

      a {
        color: #D5B981;
      }
    }

    .about .about-text p {
        font-size: 16px;
    }
    
    .about .about-text a.btn {
        position: relative;
        margin-top: 15px;
        padding: 12px 25px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #1d2434;
        border: 2px solid #1d2434;
        border-radius: 0;
        background: none;
        transition: .3s;
    }
    
    .about .about-text a.btn:hover {
        color: #D5B981;
        background: #1d2434;
    }
    
    @media (max-width: 767.98px) {
        .about .about-img {
            margin-bottom: 30px;
            height: auto;
        }
    }
    
}

@media screen and (max-width: 460px) {
  .AboutUsComponent {
    
    .about {
      padding: 20px 0;
    }
  }
}