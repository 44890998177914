$white: #fff;
$white-grey: #f0f2f5;
$grey-dark: #484e5e;
$grey-light: #7a8198;
$purple-light: #1d2434;
$purple-dark: #51576c;

//Login colors
$login-background: #eceffc;
$form-background: #1d2434;

//Header colors
$header-gradient-primary: #1d2434;
$header-gradient-secondary: #eceffc;
$header-gradient-ternary: #eceffc;

// Side menu gradient colors
$side-menu-gradient-primary: #0f0c29;
$side-menu-gradient-secondary: #302b63;
$side-menu-gradient-ternary: #24243e;

//Base Horizontal Navigation colors
$base-horizontal-navigation-background: #cccccc;
$base-horizontal-navigation-placeholder: #979797;
$base-horizontal-navigation-bottom-line: #a9a9a9;
$base-horizontal-navigation-border: #78a5cc;
$base-horizontal-navigation-bottom-line-selected: #6394c7;

//Not Found colors
$not-found-background-color: #00688B;
$not-found-secondary-text-color: #fff;