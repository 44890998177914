.AddServiceModal {
  padding: 20px;

  .FormContainer {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 10px;
      border: 1px solid #1d2434;
      padding: 5px;
    }

    textarea {
      margin-bottom: 10px;
      border: 1px solid #1d2434;
      padding: 5px;
      resize: none;
    }
  }
}