.MainDashboardHeader {
  background: #1d2434;
  color: #ffffff;

  a {
    color: #ffffff;
    margin-right: 30px;

    .logo {
      width: 150px;
    }
  }
}