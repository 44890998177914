.SliderComponent {
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;

  .carousel-caption {
    right: 15%;
    top: 50%;
    left: 5%;

    .SliderButton {
      margin: 5px;
      font-size: 20px;
      padding: 10px 30px;
      color: #D5B981;
      border-color: #D5B981;
      text-transform: uppercase;
      transition: 0.2;

      &:hover {
        background-color: #D5B981;
        color: #000;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .SliderComponent {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .SliderComponent {
    height: 100%;
  }
}