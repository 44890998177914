.ServiceDetailsModal {
  padding: 20px;

  .ServiceDetailsModalHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    
    .Title{
      font-size: 20px;
    }

    .PriceTimeContainer {
      display: flex;
      font-size: 16px;
      font-style: italic;

      .Price {
        margin-right: 30px;
      }
    }
  }
  
  .ServiceDetailsModalContent{
    display: flex;
    align-items: center;
    justify-content: center;

    >div{
      flex: 1;
    }

    .ImageContainer {

      img {
        width: 100%;
      }
    }
    .DescriptionContainer {
      margin-left: 25px;
    }
  }

  @media (max-width: 767.98px) {
    .ServiceDetailsModalHeader{
      flex-direction: column;

      .Title {
        margin-bottom: 10px;
      }

      .PriceTimeContainer {
        justify-content: space-between;
        width: 100%;
      }
    }
    .ServiceDetailsModalContent {
      flex-direction: column;

      .DescriptionContainer {
        display: block;
        max-height: 200px;
        overflow: auto;
        margin: 0;
        margin-top: 20px;
      }
    }
  } 
}