.SingleGalleryPage {

  .page-header {
    position: relative;
    margin-bottom: 45px;
    padding: 150px 0 90px 0;
    text-align: center;
    background: #D5B981;
}

.page-header h2 {
    position: relative;
    color: #1d2434;
    font-size: 60px;
    font-weight: 700;
}

.page-header a {
    position: relative;
    padding: 0 12px;
    font-size: 22px;
    color: #1d2434;
}

.page-header a:hover {
    color: #ffffff;
}

.page-header a::after {
    position: absolute;
    content: "/";
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: #1d2434;
}

.page-header a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-header h2 {
        font-size: 35px;
    }
    
    .page-header a {
        font-size: 18px;
    }
}

  .container {
    
    .PhotosContainer {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .ant-image {
        margin: 5px;
      }
    }
}

  .styles_lightroom__1X2qE {
    position: fixed;
    z-index: 999;

    .styles_lightroomcontent__1SCaZ {
      height: 100%;
    }

    .styles_lightroomdesc__rNPED {
      display: none;
    }
  }

  .styles_column__2r45X:hover {
    cursor: pointer;
  }

  .NoImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;
  }

  @media (max-width: 767.98px) {
    .page-header {
      padding: 50px 0 50px 0;
    }
  } 
}
