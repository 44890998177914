.ServicesPage {
  .page-header {
      position: relative;
      margin-bottom: 45px;
      padding: 150px 0 90px 0;
      text-align: center;
      background: #D5B981;
  }
  
  .page-header h2 {
      position: relative;
      color: #1d2434;
      font-size: 60px;
      font-weight: 700;
  }
  
  .page-header a {
      position: relative;
      padding: 0 12px;
      font-size: 22px;
      color: #1d2434;
  }
  
  .page-header a:hover {
      color: #ffffff;
  }
  
  .page-header a::after {
      position: absolute;
      content: "/";
      width: 8px;
      height: 8px;
      top: -2px;
      right: -7px;
      text-align: center;
      color: #1d2434;
  }
  
  .page-header a:last-child::after {
      display: none;
  }
  
  @media (max-width: 767.98px) {
      .page-header h2 {
          font-size: 35px;
      }
      
      .page-header a {
          font-size: 18px;
      }
  }
  .team {
      position: relative;
      width: 100%;
      padding: 45px 0 15px 0;
  }
  
  .team .team-item {
      margin-bottom: 30px;
  }
  
  .team .team-img {
    position: relative;
    height: 140px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .team .team-img::after {
      position: absolute;
      content: "";
      top: 15px;
      right: 15px;
      bottom: 15px;
      left: 15px;
      background: transparent;
      border: 1px solid #F6F6F7;
      transition: .3s;
      z-index: 1;
  }
  
  .team .team-img img {
      width: 100%;
  }
  
  .team .team-text {
      position: relative;
      margin: -25px 15px 0 15px;
      padding: 10px 15px;
      text-align: center;
      background: #F6F6F7;
      transition: .3s;
      z-index: 2;
  }
  
  .team .team-text h2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
  }
  
  .team .team-text p {
      margin: 0;
      color: #333333;
  }
  .team .team-item:hover {
      cursor: pointer;
  }

  .team .team-item:hover .team-text {
      background: #D5B981;
  }
  
  .team .team-item:hover .team-img::after {
      border-color: #D5B981;
  }

  @media (max-width: 767.98px) {
    .page-header {
      padding: 50px 0 50px 0;
    }
    .team .team-img {
      height: auto;
    }

    .ModalDialog {
      .ModalContainer {
        max-width: 80%;
      }
    }
  } 
    
}