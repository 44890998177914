.GalleryComponent {

  .ImageEffect {
    overflow: hidden;
  }

  .ImageEffect img {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(1.12);
    transform: scale(1.12);
    width: 100%;
  }
  
  .ImageEffect:hover img {
    opacity: 0.5;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  .ImageEffect .ImageCaption {
    padding: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ImageEffect .ImageCaption::before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 1px solid #fff;
    content: '';
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .ImageEffect .ImageCaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
  
  .ImageEffect .ImageCaption::before {
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
  }
  
  .ImageEffect h2 {
    padding: 10px;
    margin: 0;
    text-align: center;
    color: #fff;
    background: #00000059;
    font-size: 20px;
  }

  .ImageEffect .ImageCaption, .ImageEffect .ImageCaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
      
  .ImageEffect:hover .ImageCaption::before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    cursor: pointer;
  }
  
  @media screen and (max-width: 50em) {
    .content {
      padding: 0 10px;
      text-align: center;
    }
    .ImageEffect {
      display: inline-block;
      float: none;
      margin: 10px auto;
      width: 100%;
    }
  }
}